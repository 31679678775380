import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "drug", "area", "types", "tags", "tagsList", "helpBlock", "strict" ]

  connect() {
    this.setupAutocomplete()
  }

  // Returns the strict param for use in tag based searches
  get strictParam() {
    return "&strict=" + this.strictSearch
  }

  // Returns true or false based on the checked status of the strict checkbox in the search form.
  get strictSearch() {
    return this.strictTarget.checked
  }

  // Iterates over the type buttons with the active class and returns a string of all their names.
  get selectedTypes() {
    let types = []
    this.typesTarget.querySelectorAll('.active').forEach(type => types.push(type.id))
    return types.join(",")
  }

  // Iterates over any tag (using the label class) added to search and returns a string of their names.
  get selectedTags() {
    let tags = []
    this.tagsListTarget.querySelectorAll('.label').forEach(tag => {
      tags.push(tag.firstChild.data.replace(/(^\s+|\s+$)/g,''))
    })
    return tags.join(",")
  }

  // Encodes all the selected tags names for use in the URL
  get encodedTags() {
    return encodeURIComponent(this.selectedTags)
  }

  get selectedDrug() {
    return this.drugTarget.value
  }

  get selectedArea() {
    return this.areaTarget.value
  }

  // Manually initialize Awesomplete and set up the list for dropdown btn
  setupAutocomplete() {
    let autocomplete = new Awesomplete(this.tagsTarget, {minChars: 0, list: this.tagsTarget.dataset.list})
    Awesomplete.$('.input-group-addon').addEventListener("click", function() {
      if (autocomplete.ul.childNodes.length === 0) {
		    autocomplete.minChars = 0;
		    autocomplete.evaluate();
	    }	else if (autocomplete.ul.hasAttribute('hidden')) {
		    autocomplete.open();
      }	else {
        autocomplete.close();
      }
    })
    this.tagsTarget.setAttribute('autocomplete', 'off')
  }

  // If a drug is selected return a drug search param with the selected drugs value
  drugSearchString() {
    if (this.selectedDrug.length) {
      return "drug=" + this.selectedDrug
    } else {
      return ""
    }
  }

  // If an area is selected return an area search param with the selected areas value
  areaSearchString(searchString) {
    if (this.selectedArea.length) {
      let baseString = this.setBaseString(searchString, 'area')
      return baseString + this.selectedArea
    } else {
      return ""
    }
  }

  // If any types are selected return a types search param with the selected types values
  typeSearchString(searchString) {
    if (this.selectedTypes.length) {
      let baseString = this.setBaseString(searchString, 'types')
      return baseString + this.selectedTypes
    } else {
      return ""
    }
  }

  // If any tags are selected return a tags search param with the selected tags values with a strict param
  tagSearchString(searchString) {
    if (this.selectedTags.length) {
      let baseString = this.setBaseString(searchString, 'tags')
      return baseString + this.encodedTags + this.strictParam
    } else {
      return ""
    }
  }

  // Handles setting '&' based on the params position in the search string
  setBaseString(searchString, param) {
    return searchString.length > 1 ? `&${param}=` : `${param}=`
  }

  submit() {
    let searchString = this.buildSearchString()
    location.search = searchString
  }

  buildSearchString() {
    let searchString = "?"
    searchString += this.drugSearchString()
    searchString += this.areaSearchString(searchString)
    searchString += this.typeSearchString(searchString)
    searchString += this.tagSearchString(searchString)
    return searchString
  }

  // If tag has already been added to search raise an error, other wise add the tag.
  addTag() {
    let tag = this.tagsTarget.value
    this.tagsTarget.value = ""
    if (this.selectedTags.includes(tag)) {
      this.duplicateTagError()
    } else {
      this.insertTag(tag)
    }
  }

  duplicateTagError() {
    this.helpBlockTarget.innerText = 'Tag already selected'
  }

  // Inserts tag to the end of the tags list
  insertTag(tag) {
    let tagDiv = this.createTag(tag.replace(/(^\s+|\s+$)/g,''))
    this.tagsListTarget.appendChild(tagDiv)
  }

  // Creates a <div> with a class of tag with a label inside
  createTag(tag) {
    let tagDiv = document.createElement('div')
    tagDiv.classList.add('tag')
    tagDiv.appendChild(this.createLabel(tag))
    return tagDiv
  }

  // Creates a <div> with the proper label classes, and puts the tag name with a close button inside itself.
  createLabel(tag) {
    let label = document.createElement('div')
    // This horse shit brought to you by IE
    // and its fucking lack of support for
    // multiple arguments and the god damn spread syntax.
    // Fuck you, IE.
    label.classList.add("label")
    label.classList.add("label-tag")
    label.classList.add("label-tag-search")
    label.innerText = tag + " "
    label.appendChild(this.closeBtn())
    return label
  }

  // Creates a clickable X for remove a tag from the list.
  // Returns a <span> with a class of clickable, with attached data-actions, and a UTF-8 x inside.
  closeBtn() {
    let closeBtn = document.createElement('span')
    closeBtn.classList.add('clickable')
    closeBtn.dataset.action = "click->search#deleteTag mouseenter->search#deleteHover mouseleave->search#deleteLeave"
    closeBtn.innerHTML = "&times;"
    return closeBtn
  }

  noTagError(e) {
    if (e.reason === "nomatches") {
      this.helpBlockTarget.innerText = 'No matching tag'
    }
  }

  clearErrors() {
    this.helpBlockTarget.innerText = ''
  }

  deleteTag(e) {
    // e.target will point to the span inside the label but we need the tag element to remove it from the list.
    // <div class="tag"><div class="label"><span class="clickable"></span></div></div>
    let label = e.target.parentElement.parentElement
    // Only try to remove the tag if it is found
    // Question: Can we search the tagsList node for the label before trying to remove it? That would better avoid errors.
    if (label) {
      this.tagsListTarget.removeChild(label)
    }
  }

  // Handles setting the active state and icon of a type btn when clicked
  typeSelect(e) {
    e.currentTarget.classList.toggle('active')
    e.currentTarget.firstElementChild.classList.toggle("glyphicon-check")
    e.currentTarget.firstElementChild.classList.toggle("glyphicon-unchecked")
  }

  // Set all values and selections back to default, clear the tags list, and runs an empty search.
  reset() {
    this.drugTarget.selectedIndex = 0
    this.areaTarget.selectedIndex = 0
    this.tagsTarget.value = ""
    this.tagsListTarget.innerHTML = ""
    this.resetTypeBtns()
    window.location.search = ''
  }

  // Iterates over all type buttons and removes active state and sets the icon to unchecked.
  resetTypeBtns() {
    for (var i = 0; i < this.typesTarget.children.length; i++) {
      this.typesTarget.children[i].classList.remove('active')
      this.typesTarget.children[i].firstElementChild.classList.remove('glyphicon-check')
      this.typesTarget.children[i].firstElementChild.classList.add('glyphicon-unchecked')
    }
  }

  // adds a strike though to a tag name when the close span is hovered over
  deleteHover(e) {
    let label = e.target.parentElement
    label.style.textDecoration = "line-through"
  }

  // removes any strike though to a tag name when the close span is no longer hovered over
  deleteLeave(e) {
    let label = e.target.parentElement
    label.style.textDecoration = "none"
  }
}
