import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["admin", "okta"]

  flip () {
    this.adminTarget.classList.toggle("hidden")
    this.oktaTarget.classList.toggle("hidden")
  }
}
