import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["titleField"]

  connect() {
    // This sets the title text area required attribute to false.
    // We need to do this so that the bootstrap form builder with give
    // the text area the proper required context but the browser wont use
    // the ugly HTML required field blocker.
    this.titleFieldTarget.querySelector('textarea').required = false
  }

  submit(e) {
    if (!this.formValid()) {
      // Stops direct_upload from starting upload
      e.stopImmediatePropagation()
      // Stops forms from submiting
      e.preventDefault()
    }
  }

  // Clears any existing errors and checks fields for validation
  formValid() {
    this.clearErrors()
    let titleValid = this.validateField(this.titleFieldTarget)
    return titleValid
  }

  // checks field validation, sets an error if invalid, returns validation status
  validateField(target) {
    let value = this.fieldValue(target)
    let valid = value.length > 0
    if (!valid) {
      this.addError(target)
    }
    return valid
  }

  // Accessor for the contents of the input of a requested field
  fieldValue(target) {
    return target.querySelector('textarea').value
  }

  // Adds error context to a field
  addError(target) {
    let label = this.fieldLabel(target)
    let errorMessage = this.createErrorMessage(label)
    target.appendChild(errorMessage)
    target.classList.add('has-error')
    target.querySelector('textarea').focus()
  }

  // Creates a helper block element with an error message
  createErrorMessage(label) {
    let errorBlock = document.createElement('span')
    errorBlock.classList.add('help-block')
    errorBlock.innerText = `${label} can't be blank.`
    return errorBlock
  }

  // Accessor for the Label of a requested field
  fieldLabel(target) {
    return target.querySelector('label').innerText
  }

  clearErrors() {
    this.clearError(this.titleFieldTarget)
  }

  // Removes error context from a requested field
  clearError(target) {
    target.classList.remove('has-error')
    let message = target.querySelector('span')
    // only remove the helper block if it exists
    if (message) {
      target.removeChild(message)
    }
  }
}
