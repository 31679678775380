// This Controller is used in the new/edit Document form but only handles the tags list.
import { Controller } from 'stimulus'
import 'awesomplete'

export default class extends Controller {
  static targets = ['tagToAdd', 'tags', 'tagsList', 'helpBlock']

  connect() {
    this.setupAutocomplete()
    this.populateTagList()
  }

  // Returns true or false based on the length of the content in the hidden input
  get tagsPresent() {
    return this.tagsTarget.value.length > 0
  }

  // Returns an Array of the tag names in the hidden input
  get currentTags() {
    return this.tagsTarget.value.split(',')
  }

  // Returns the name of autocomplete list
  get listName() {
    return this.tagToAddTarget.getAttribute('aria-owns')
  }

  // Uses the name of the autocomplete list to find the list and return true or false based on it hidden status (open/closed)
  get listOpen() {
    let list = this.listName
    return !this.element.querySelector(`#${list}`).hidden
  }

  // Sets up Awesomplete and then turns the inputs autocomplete off so the browser does not hijack the autocomplete functions
  setupAutocomplete() {
    new Awesomplete(this.tagToAddTarget, {
      list: this.tagToAddTarget.dataset.list
    })
    this.tagToAddTarget.setAttribute('autocomplete', 'off')
  }

  // If there are any tags in the hidden field iterates over those tags and adds them to the visual tag list area
  populateTagList() {
    if (this.tagsPresent) {
      this.currentTags.forEach(tag => {
        this.tagsListTarget.appendChild(this.createTag(tag))
      })
    }
  }

  // Creates a <div> with a class of tag with a label inside
  createTag(tag) {
    let tagDiv = document.createElement('div')
    tagDiv.classList.add('tag')
    tagDiv.appendChild(this.createLabel(tag))
    return tagDiv
  }

  // Creates a <div> with the proper label classes, an id of the tag name, and puts the tag name with a close button inside.
  createLabel(tag) {
    let label = document.createElement('div')
    label.id = tag
    // This horse shit brought to you by IE
    // and its fucking lack of support for
    // multiple arguments and the god damn spread syntax.
    // Fuck you, IE.
    label.classList.add('label')
    label.classList.add('label-tag')
    label.classList.add('label-tag-search')
    label.innerText = tag + ' '
    label.appendChild(this.closeBtn())
    return label
  }

  // Creates a clickable X for remove a tag from the list.
  // Returns a <span> with a class of clickable, with attached data-actions, and a UTF-8 x inside.
  closeBtn() {
    let closeBtn = document.createElement('span')
    closeBtn.classList.add('clickable')
    closeBtn.dataset.action =
      'click->tags#deleteTag mouseenter->tags#deleteHover mouseleave->tags#deleteLeave'
    closeBtn.innerHTML = '&times;'
    return closeBtn
  }

  // If tag is already in the hidden field add an error, otherwise add the tag and reset the visual tag list.
  addTag() {
    let tag = this.tagToAddTarget.value
    this.tagToAddTarget.value = ''
    if (this.tagsTarget.value.includes(tag)) {
      this.duplicateTagError()
    } else {
      this.addTagToList(tag)
      this.resetTagList()
    }
  }

  duplicateTagError() {
    this.addError('Tag already selected')
  }

  addTagToList(tag) {
    if (this.tagsPresent) {
      this.tagsTarget.value += `, ${tag}`
    } else {
      this.tagsTarget.value = `${tag}`
    }
  }

  // Adds error context and sets the error message
  addError(message) {
    this.helpBlockTarget.parentElement.classList.add('has-error')
    this.helpBlockTarget.innerText = message
  }

  // Clears the visual tag list and repopulates based on the current tags in the hidden field.
  resetTagList() {
    this.clearTagList()
    this.populateTagList()
  }

  clearTagList() {
    this.tagsListTarget.innerHTML = ''
  }

  // Removes the tag from the list of tags in the hidden field and resets the visual tag list
  deleteTag(e) {
    debugger
    // tag ids are the tag name
    let selectedTag = e.target.parentElement.id
    // Only keeps tags that aren't the selected tag
    let filteredTags = this.currentTags.filter(tag => tag != selectedTag)
    // Replaces the hidden field value
    this.tagsTarget.value = filteredTags.join(',')
    this.resetTagList()
  }

  // Called by awesomplete event -> close
  // Called any time there is input
  noTagError(e) {
    // Event reason is 'nomatches' (sent when awesomplete list closes)
    // Or the list is not open when there is input
    let noMatches = e.reason === 'nomatches' || !this.listOpen
    // If no matches in awesomplete AND there is more than 2 chars in the input raise an error
    // Otherwise clearErrors
    if (noMatches && this.tagToAddTarget.value.length > 2) {
      this.addError('No Matching Tag')
    } else {
      this.clearErrors()
    }
  }

  // Also called by awesomplete event -> open, input field -> focus
  // Removes error context and message
  clearErrors() {
    this.helpBlockTarget.parentElement.classList.remove('has-error')
    this.helpBlockTarget.innerText = ''
  }

  // Adds a strike though to a tag name when the close span is hovered over
  deleteHover(e) {
    let label = e.target.parentElement
    label.style.textDecoration = 'line-through'
  }

  // Removes any strike though to a tag name when the close span is no longer hovered over
  deleteLeave(e) {
    let label = e.target.parentElement
    label.style.textDecoration = 'none'
  }
}
